@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@200&family=Grand+Hotel&family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,300&family=Antonio:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@200&family=Grand+Hotel&family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,300&family=Exo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);

:root {
  --colorThemeBlue: #005194;
  --colorThemeRed: #D71433;
  --colorPrimaryBtnText:#ffffff ;
  --colorPrimaryBtnBackground:#339ecc ;
  --colorSecondaryBtnText:#000000;
  --colorSecondaryBtnBackground:#bfb170;
  --colorSecondaryBtnOutlineText:#ffffff;
  --colorSecondaryBtnOutlineBackground:transparent;

  --heading-font: Antonio;
  --button-font: Exo;										/* Heading font */
  --secondary-color:  #e3a724;
  --secondary-bg-color:  #bfb170;
  
  --subtext-color:  #a5a5a5;
  --primary-color: rgb(9 32 62);
   --colorPrimaryHeading: rgb(68 119 183);
  --primaryFont: 'Poppins';
  --secondaryFont: 'Montserrat';
  --thirdFont: 'Assistant';
  --secondaryTextColor: gray;
  /* Not in use down one only just testing */
  --color-bg-intro: hsl(217, 28%, 15%);
  --color-bg-main: hsl(218, 28%, 13%);
  --color-bg-footer: hsl(216, 53%, 9%);
  --color-bg-testimonials: hsl(219, 30%, 18%);
  --color-cyan: hsl(176, 68%, 64%);
  --color-error: hsl(0, 100%, 63%);
  --size-section-signup-margin-top: 160px;
  --size-section-signup-offset: 150px;
  --size-footer-padding-top: 30px;
  --size-footer-padding-bottom: 30px;
}

*, 
*::before, 
*::after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
body {
  margin: 0;
  font-size: 16px;
  /* line-height: 1.5; */
  scroll-behavior: smooth;
  font-family: var(--secondaryFont);
}
a[href]{
  cursor:pointer;
  text-decoration:none;
}
h1,.h1 {
  font-size: 1.5rem;
}
h2,.h2,.h3, h3 {
  font-size: 1.125rem;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
  color: inherit;
}
p{
  margin: 0 0 15px 0;
  font-family: var(--secondaryFont);
}

ul {
  list-style: none;
  padding: 0;
}
input[type='email'], 
input[type='submit'] {
  appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  border: 0;
  font-size: inherit;
  font-family: inherit;
}
input[type='email'] {
  width: 100%;
  height: 48px;
  border-radius: 999px;
  padding: 0 2.5rem;
  font-size: 0.75rem;
}
button[disabled],
button.disabled{
  cursor:not-allowed;
  cursor: not-allowed;
}


@media (min-width: 1024px) {
  h1 {
      font-size: 2.5rem;
      line-height: 1.5;
 }
  h2 {
      font-size: 2rem;
      line-height: 1.25;
 }
  h3 {
      font-size: 1.25rem;
 }
}

.btn {
  font-weight: 600;
  padding:15px 15px;
  border:1px solid #fafafa;
  border:0;
  cursor: pointer;
  outline:none;
  display:flex;
  justify-content:center;
  align-items: center;
  font-family: var(--button-font);
  letter-spacing: 1px;
  border-radius: 100vw;
}

.btn--primary{
  color: var(--colorPrimaryBtnText);
  background-color: var(--secondary-color);
  font-family: var(--button-font);
}

.btn--secondary{
  color: var(--colorSecondaryBtnText);
  background-color: var(--colorSecondaryBtnBackground);
  font-family: var(--button-font);
}

.btn--secondary--outline{
  color: var(--colorSecondaryBtnOutlineText);
  background-color: var(--colorSecondaryBtnOutlineBackground);
  border:3px solid var(--colorSecondaryBtnOutlineText);
}
.btn--secondary--outline:hover{
  color: var(--colorSecondaryBtnText);
  background-color: var(--secondary-color);
  border:3px solid var(--secondary-color);
}
.btn:hover, 
.btn:focus {
  outline:none;
}
.btn--primary:hover, 
.btn--primary:focus {
  outline:none;
  color: var(--colorPrimaryBtnText);
  /* background-color: var(--secondary-bg-color); */
  border-color:var(--colorPrimaryBtnBackground) ;
}
.btn--secondary:hover, 
.btn--secondary:focus {
  outline:none;
  
}
.btn--full{
  width:100%;
  display:block;
}

.btn--half{
  min-width:150px;
}


.button-svg {
  background:none;
  outline: none;
  border: none;
  cursor: pointer;
}
a.button {
  font-size: 1rem;
  min-width: 100px;
}

.icon{
  width:30px;
  height:30px;
  vertical-align: middle;
  display: inline-block;
  fill: inherit;
}
.rte{
  width:100%;
  overflow: hidden;
  margin: 15px 0px;
}
.rte p + p{
  margin:0;
}
.rte p:last-child{
  margin:0px 0px 15px 0px;
}
.rte h1,
.rte h2,
.rte h3{
  margin:0px 0px 15px 0px; 
}
a.btn{
  width:fit-content;
}

.section__title{
  font-weight:900;
  font-family: var(--heading-font);
  text-transform: capitalize;
  text-align: left;
  border-left:10px solid var(--secondary-color);
  line-height: 1.1;
  text-transform: capitalize;
  padding-left: 0.5em;
  color:var(--primary-color);
  margin:0px 0px  15px 0px;
}
.section__title_secondary{
  font-weight:900;
  font-family: var(--heading-font);
  text-transform: capitalize;
  text-align: left;
  border-left:10px solid white;
  line-height: 1.1;
  text-transform: capitalize;
  padding-left: 0.5em;
  color:var(--primary-color);
  margin:0px 0px  15px 0px;
}
@media only screen and (max-width: 767px) {
  .section__title{
  font-weight:900;
  padding-left: 0.3em;
  }
  .section__title_secondary{
    font-weight:900;
    padding-left: 0.3em;
    }
  }
  
.flex-box{
  display: flex;
}

.primary-color{
  color:var(--primary-color);
}
.secondary-color{
  color:var(--secondary-color);
}
/* *::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #000; 
  border-radius: 8px;
}
 
*::-webkit-scrollbar-thumb {
  background: var(--colorTheme); 
  border-radius: 8px;
}
*::-webkit-scrollbar-thumb:hover {
  background: var(--colorTheme); 
} */


/* Cookie Banner */
.cookie__banner{
  position:fixed;
  display:none;
  bottom:0;
  left:0;
  z-index: 1;
}
.cookie__banner.active{
  display:block;
}
.icon--cc{
  width:100px;
  height:100px;
  fill: inherit;
  display:block;
}

@media(max-width:480px){
  .cookie__banner{
    bottom:10px;
    left:10px;
    right:10px;
  }
  .cookie__wrapper-main{
    margin:0em;
    max-width:300px;
  }
  .cookie__wrapper-main .icon--cc{
    width:50px;
    height:50px;
  }
}


[data-page="/pages/pricing"] header > div{
  box-shadow: none;
}