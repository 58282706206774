.header__main-wrap{
    display: flex;
    align-items: center;
    max-width: 1290px;
    margin: 0 auto;
    
}

@media(max-width:992px){
    .header__main-wrap{
      padding: 1em;
      justify-content: space-between;
    } 
}

