.css-1elwnq4-MuiPaper-root-MuiAccordion-root::before {
    position: absolute;
    left: 0;
    top: -1px;
    right: 0;
    height: 0px;
    content: "";
    opacity: 1;
    background-color: none;
    -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  